import React from 'react'
import './styles/App.css'

import Wave1 from './assets/image/Wave1.png'

import Home from './components/Home';
import Benchmark from './components/Benchmark';
import About from './components/About';
import Process from './components/Process'
import Platform from './components/Platform'
import Message from './components/Message'
import Join from './components/Join'
import Information from './components/Information';
import InformationMobile from './components/InformationMobile';

function Main() {

  return (
    <div className='w-screen flex justify-center'>
      <div className='flex justify-center w-full flex-col items-center gap-3 text-white'>
        <div className='w-screen overflow-hidden flex items-center justify-center'>
          <Home />
        </div>
        <div className='min-h-[100px] md:min-h-[362px] w-full flex gap-[40px] relative justify-center items-center'>
          <img src={Wave1} alt='' className='w-full absolute' />
        </div>
        <div className='w-screen overflow-hidden flex items-center justify-center'>
          <Benchmark />
        </div>
        <About />
        <div className='w-screen overflow-hidden flex items-center justify-center'>
          <Process />
        </div>
        <div className='w-screen overflow-hidden flex items-center justify-center'>
          <Platform />
        </div>
        <div className='w-full hidden lg:flex items-center justify-center'>
          <Information/>
        </div>
        <div className='w-[95%] flex lg:hidden items-center justify-center'>
          <InformationMobile/>
        </div>
        <Message />
        <Join />
      </div>
    </div>
  )
}

export default Main