import React from 'react'
import { LogoSvg } from '../assets/svg'

function Footer() {
  return (
    <div className='w-[95%]  text-white md:w-full bg-black flex flex-col justify-center items-center'>
          <div className='max-w-[1320px] py-10 md:w-[95%] w-full flex flex-col gap-6 md:flex-row relative items-start justify-between'>
            <div className=' h-[200px] w-[200px] bluer absolute --blur-effect bg-[#9747ff34] '></div>
            <div className=' h-[300px] w-[300px] bottom-0 right-0 bluer absolute --blur-effect bg-[#9747ff34] '></div>

            <div className='flex flex-col items-start md:w-[40%]'>
              <span className='flex items-center text-[40px] gap-3'>
                <LogoSvg className='w-[40px]' />
                NUMBER
              </span>
              <span className='text-[#CDCDCD] text-sm lg:text-base lg:w-[50%]'>Redefining Connectivity: Decentralized, Secure, Unparalleled</span>
            </div>
            <div className='flex relative items-start w-full md:w-[60%] justify-between'>
              <div className='flex flex-col items-start '>
                <span className='font-medium text-[20px] md:text-[24px] text-white'>Contract</span>
                <div className='flex flex-col mt-3 md:mt-5 gap-3 text-sm items-start text-[#CDCDCD]'>
                  <a href='https://t.me/OxNumber'>Telegram</a>
                  <a href='https://t.me/oxnumber_bot'>Telegram Bot</a>
                  <a href='https://x.com/0xNumberETH'>Twitter</a>
                  <a href='mailto:contact@0xnumber.io'>Support</a>
                  <a href='/'>Partnership Program</a>
                </div>
              </div>
              <div className='flex flex-col items-start'>
                <span className='font-medium text-[20px] md:text-[24px] text-white'>Social</span>
                <div className='flex flex-col mt-3 md:mt-5 gap-3 text-sm items-start text-[#CDCDCD]'>
                  <a href='https://dexscreener.com/ethereum/0x9012744B7A564623b6C3E40b144fc196bdeDf1A9'>Etherscan Contract</a>
                  <a href='https://etherscan.io/address/0x9012744B7A564623b6C3E40b144fc196bdeDf1A9'>Etherscan Holders</a>
                  <a href='/'>Audit</a>
                </div>

              </div>
              <div className='flex flex-col items-start'>
                <span className='font-medium text-[20px] md:text-[24px] text-white'>Social</span>
                <div className='flex flex-col mt-3 md:mt-5 gap-3 text-sm items-start text-[#CDCDCD]'>
                  <a href='https://www.dextools.io/app/en/ether/pair-explorer/0x9012744B7A564623b6C3E40b144fc196bdeDf1A9'>Dextools</a>
                  <a href='https://dexscreener.com/ethereum/0x9012744B7A564623b6C3E40b144fc196bdeDf1A9'>Dexscreeners</a>
                </div>
              </div>
            </div>
          </div>
          <div className='bg-[#d9d9d91b] w-full h-px'></div>
          <div className='max-w-[1320px] py-6 md:w-[95%] w-full flex relative items-start justify-between'>
            <span className=' text-xs md:text-sm'>@OXNUMBER V2.1 2024</span>
            <div className='flex items-center lg:gap-10 gap-3 text-xs md:text-sm'>
              <a href='https://x.com/0xNumberETH'>TWITTER</a>
              <a href='https://t.me/OxNumber'>TELEGRAM</a>
              <a href='/'>CONTACT</a>
            </div>
          </div>
        </div>
  )
}

export default Footer