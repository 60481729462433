import React from 'react'
import { motion } from "framer-motion"
import Wave4 from '../assets/image/Wave4.png'

function Join() {
    return (
        <div className=' min-h-[462px] lg:min-h-[862px] w-full relative flex flex-col justify-start pt-16 items-center'>
            <motion.img
                initial={{ translateY: 50, opacity: 0 }}
                whileInView={{ translateY: 0, opacity: 1 }}
                viewport={{ once: true }}
                src={Wave4} className='absolute w-full z-0 bottom-0' alt='' />
            <div className=' w-[95%]  md:w-full flex flex-col items-center'>
                <motion.span
                    initial={{ translateY: 50, opacity: 0 }}
                    whileInView={{ translateY: 0, opacity: 1 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    className=' lg:w-[60%] md:w-[70%] text-center max-w-[1320px] z-20 text-[18px] lg:text-[32px]'> <br />
                    Become Part of the 0xNumber Movement Alongside Thousands of Investors, Consumers, and Members.</motion.span>
                <a className='w-fit bg-gradient-to-r from-[#FE00FE] to-[#0000FF] relative  mt-10 z-20 rounded-full duration-200 hover:scale-95 p-px' href='https://t.me/OxNumber'>
                    <button className='bg-black px-10 py-2 text-base rounded-full text-white'>
                        <span className=''>Join our community</span>
                    </button>
                </a>
                {/* <motion.button
              initial={{ scale: 0.2, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              viewport={{ once: true }}
              className='--button-join z-20 mt-10 w-[80%] md:w-[50%] lg:w-[30%] tracking-widest rounded-full hover:scale-95 duration-150 text-black py-2'>JOIN OUR COMMUNITY</motion.button> */}
            </div>
        </div>
    )
}

export default Join