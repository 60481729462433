import React from 'react'
import { motion } from "framer-motion"
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import NavLogo from '../assets/image/NavLogo.png'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

function Benchmark() {
    return (
        <div id='benchmark' className=' lg:min-h-[862px] overflow-hidden mt-9 md:mt-0 w-[95%] lg:w-full max-w-[1320px] flex lg:flex-row flex-col gap-[30px] lg:gap-[80px] justify-between items-center'>
            {/* <motion.div
                initial={{ translateX: -150 }}
                whileInView={{ translateX: 0 }}
                viewport={{ once: true }}
                className='flex flex-col items-start w-full md:w-1/2 gap-4 lg:gap-8'>
                <span className='text-[32px] lg:text-[48px]'>Benchmarking our utility</span>
                <span className='text-base'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitat ion ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</span>
                <button className='bg-transparent  duration-200 hover:scale-95 px-4 py-2 border border-white rounded-full'>Explore our Platform</button>
            </motion.div> */}
            <motion.div
                initial={{ translateX: -150 }}
                whileInView={{ translateX: 0 }}
                viewport={{ once: true }}
                className='w-[95%] lg:w-1/2 flex flex-col items-start text-xs md:text-base gap-3'>
                <div className='w-full flex items-center justify-between border-b-2 border-[#9747FF] pb-1'>
                    <div><img src={NavLogo} className='w-[100px]' alt='' /></div>
                    <span>Comparison</span>
                </div>
                <div className='flex flex-col w-full'>
                    <table>
                        <thead>
                            <tr>
                                <th className='md:py-2 w-[40%]'></th>
                                <th className='md:py-2 w-[30%] rounded-t-lg bg-[#6b2dbb]'>OxNumber</th>
                                <th className='md:py-2 w-[30%]'>Conventional Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='bg-[rgb(18,18,18)] !rounded-t-lg'>
                                <td className='py-1 text-left font-bold !rounded-tl-xl pl-4'>KYC Free</td>
                                <td className='bg-[#6b2dbbb6] py-1 text-center'><CheckIcon /></td>
                                <td className=' py-1 text-center !rounded-tr-xl'><ClearIcon/></td>
                            </tr>
                            <tr className='bg-[rgb(40,40,42)]'>
                                <td className='py-1 text-left font-bold px-2 md:pl-4'>No Registration Required</td>
                                <td className='bg-[#6b2dbb] py-1 text-center'><CheckIcon /></td>
                                <td className=' py-1 text-center'><ClearIcon/></td>
                            </tr>
                            <tr className='bg-[rgb(18,18,18)]'>
                                <td className='py-1 text-left font-bold px-2 md:pl-4'>SimSwap Protection</td>
                                <td className='bg-[#6b2dbbb6] py-1 text-center'><CheckIcon /></td>
                                <td className=' py-1 text-center'><ClearIcon/></td>
                            </tr>
                            <tr className='bg-[rgb(40,40,42)]'>
                                <td className='py-1 text-left font-bold px-2 md:pl-4'>Easy To Buy</td>
                                <td className='bg-[#6b2dbb] py-1 text-center'><CheckIcon /></td>
                                <td className=' py-1 text-center'><ClearIcon/></td>
                            </tr>
                            <tr className='bg-[rgb(18,18,18)]'>
                                <td className='py-1 text-left font-bold px-2 md:pl-4'>Instant Purchase With Crypto</td>
                                <td className='bg-[#6b2dbbb6] py-1 text-center'><CheckIcon /></td>
                                <td className=' py-1 text-center'><ClearIcon/></td>
                            </tr>
                            <tr className='bg-[rgb(40,40,42)]'>
                                <td className='py-1 text-left font-bold px-2 md:pl-4'>Choose Country Number</td>
                                <td className='bg-[#6b2dbb] py-1 text-center'><CheckIcon /></td>
                                <td className=' py-1 text-center'><ClearIcon/></td>
                            </tr>
                            <tr className='bg-[rgb(18,18,18)]'>
                                <td className='py-1 text-left font-bold px-2 md:pl-4'>Decentralized Platform</td>
                                <td className='bg-[#6b2dbbb6] py-1 text-center'><CheckIcon /></td>
                                <td className=' py-1 text-center'><ClearIcon/></td>
                            </tr>
                            <tr className='bg-[rgb(40,40,42)]'>
                                <td className='py-1 text-left font-bold px-2 md:pl-4'>Multi-chain Support</td>
                                <td className='bg-[#6b2dbb] py-1 text-center'><CheckIcon /></td>
                                <td className=' py-1 text-center'><ClearIcon/></td>
                            </tr>
                            <tr className='bg-[rgb(18,18,18)]'>
                                <td className='py-1 text-left font-bold !rounded-bl-xl px-2 md:pl-4'>24/7 Live Support</td>
                                <td className='bg-[#6b2dbbb6] py-1 text-center'><CheckIcon /></td>
                                <td className=' py-1 text-center !rounded-br-xl'><ClearIcon/></td>
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
                <div className='w-full flex items-center justify-center'>
                    <div className='border border-[#6b2dbbb6] p-2 rounded-md flex items-center gap-1'>
                        <ArrowRightAltIcon className='!text-[#6b2dbb]' />
                        <span>Trade now at</span>
                        <span className='text-[#9747FF]'>OxNumber.io</span>
                    </div>
                </div>
            </motion.div>
            <div className='flex w-[95%] lg:w-1/2 gap-3 flex-col items-center'>
                <motion.div
                    initial={{ translateX: 200 }}
                    whileInView={{ translateX: 0 }}
                    viewport={{ once: true }}
                    className='flex flex-col w-full relative z-20  bg-gradient-to-r from-[#ff00f731] to-[#000] rounded-lg items-center p-6 border border-[#1E1E1E]'>
                    <span className='text-[32px] md:text-[64px] text-[#9747FF]'>273+</span>
                    <span className='text-xs text-center md:text-base text-[#CDCDCD]'>Unlock Worldwide Access with Our Virtual Numbers.</span>
                </motion.div>
                <div className='flex w-full relative z-10 items-center gap-3'>
                    <motion.div
                        initial={{ translateX: 170, translateY: -180, opacity: 0 }}
                        whileInView={{ translateX: 0, translateY: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ delay: 0.3 }}
                        className='flex flex-col w-full bg-gradient-to-r from-[#ff00f731] to-[#10101000] rounded-lg items-center p-6 border border-[#1E1E1E]'>
                        <span className='text-[32px] md:text-[64px] text-[#9747FF]'>700+</span>
                        <span className='text-xs text-center md:text-base text-[#CDCDCD]'>Supported Apps and Websites</span>
                    </motion.div>
                    <motion.div
                        initial={{ translateX: -170, translateY: -180, opacity: 0 }}
                        whileInView={{ translateX: 0, translateY: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ delay: 0.3 }}
                        className='flex flex-col w-full bg-gradient-to-r from-[#ff00f731] to-[#10101000] rounded-lg items-center p-6 border border-[#1E1E1E]'>
                        <span className='text-[32px] md:text-[64px] text-[#9747FF]'>$5.23</span>
                        <span className='text-xs text-center md:text-base text-[#CDCDCD]'>Average cost per VERIFICATION</span>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default Benchmark