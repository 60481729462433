import React from 'react'
import { motion } from "framer-motion"
import { copyToClipboard } from '../utils/config'
import { Copy, ScrollSvg } from '../assets/svg'
import { ArrowRightAltOutlined } from '@mui/icons-material'

function Home() {

    return (
        <div id='home' className='min-h-[660px] relative overflow-hidden lg:min-h-[862px] w-[95%] lg:w-full flex flex-col max-w-[1320px] gap-[20px] lg:gap-[40px] justify-center items-center'>
            <motion.div
                initial={{ translateY: 100, opacity: 0 }}
                whileInView={{ translateY: 0, opacity: 1 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: true }}
                className="center-box relative z-20 p-[1.5px] flex items-center justify-center">
                <div className="animated-border-box-glow"></div>
                <div className="animated-border-box">
                </div>
                <div onClick={() => copyToClipboard('0x9012744B7A564623b6C3E40b144fc196bdeDf1A9', 'Contract')} className='relative z-40 border border-[#7A7A7A] bg-black hover:bg-[#141414] rounded-full text-xs md:text-base flex items-center'>
                    <div className='z-50 py-2 px-4 rounded-l-full text-[#7A7A7A]'><span>Contract Address</span></div>
                    <div className='select-none  text-[#7A7A7A] cursor-pointer group py-2 px-4 rounded-r-full flex items-center'>
                        <span>0x0090x........196bdedf1a992910</span>
                        <button><Copy /></button>
                    </div>
                </div>
            </motion.div>
            <motion.div
                initial={{ translateY: 100, opacity: 0 }}
                whileInView={{ translateY: 0, opacity: 1 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: true }}
                className='flex flex-col items-center w-full lg:text-[54px] lg:leading-[64px]  font-bold md:text-[48px] text-[28px]'>
                <span className=' text-center '>
                    Protect <span className='--text-privacy'>Your Privacy</span>
                </span>
                <span>Safeguard Your Identity</span>
            </motion.div>
            <motion.span
                initial={{ translateY: 100, opacity: 0 }}
                whileInView={{ translateY: 0, opacity: 1 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: true }}
                className='lg:w-[40%] md:w-[60%] w-[80%] text-center'>Seamlessly Manage SMS & Calls with Virtual Numbers for Easy Account Sign-Ups Across Services and Apps.</motion.span>
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, delay: 1 }}
                viewport={{ once: true }}
                className='flex items-center gap-4 mt-6'>
               
                <a className='w-fit bg-gradient-to-r from-[#FE00FE] to-[#0000FF] rounded-full duration-200 hover:scale-95 p-px' href='https://t.me/oxnumber_bot'>
                    <button className='bg-black px-4 py-2 text-base rounded-full text-white'>
                        <span className='--text-privacy'>Platform</span>
                    </button>
                </a>
                <a className='w-fit' href='https://dapp.0xnumber.io/'>
                    <button className='bg-white duration-200 group hover:scale-95 px-4 py-2 text-base rounded-full border border-white text-black'>
                        Dapp
                        <span className='group-hover:ml-3 ml-1 duration-150'><ArrowRightAltOutlined/></span>
                    </button>
                </a>
                {/* <a className='w-fit bg-gradient-to-r from-[#0000FF] to-[#FE00FE] rounded-full duration-200 hover:scale-95 p-px' href='https://t.me/oxnumber_bot'>
                    <button className='bg-black px-4 py-2 text-base rounded-full text-white'>
                        <span>Dapp</span>
                    </button>
                </a> */}
            </motion.div>
            <motion.a
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, delay: 1 }}
                viewport={{ once: true }}
                href='#benchmark' className='w-fit group'>
                <ScrollSvg className='mt-10 group-hover:scale-95 duration-300' />
            </motion.a>
        </div>
    )
}

export default Home