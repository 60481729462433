import React from 'react'
import { motion } from "framer-motion"
import Wave2 from '../assets/image/Wave2.png'
import { SendSvg } from '../assets/svg'

function Message() {
  return (
    <div className=' mt-[60px] lg:mt-[200px] w-[95%]  lg:w-full max-w-[1320px] flex flex-col justify-start items-center'>
      <div className='bg-[#101010] border flex flex-col gap-7 lg:gap-[50px] overflow-hidden items-center relative border-[#575757] rounded-3xl py-[40px] w-full'>
        <motion.img
          initial={{ translateX: 300, translateY: 100, rotate: 15, scale: 2 }}
          whileInView={{ translateX: 0, translateY: 0, rotate: 15, scale: 2 }}
          viewport={{ once: true }}
          src={Wave2} alt='' className='absolute w-full opacity-90 bottom-0 right-0 z-0' />
        <motion.span
          initial={{ translateY: 100, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className='lg:text-[32px] w-[90%] lg:w-[55%] relative z-10 text-center'>Stay Ahead. Get Exclusive Updates & Insights with Our Newsletter.</motion.span>
        <motion.div
          initial={{ translateY: 50, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className='relative w-[80%] lg:w-[40%]'>
          <SendSvg className='absolute right-4 z-20 top-5' />
          <input className='bg-[#101010] border relative z-10 outline-none w-full border-[#1E1E1E] px-6 py-4 rounded-xl' placeholder='Enter Your Email' />
        </motion.div>
      </div>
    </div>
  )
}

export default Message