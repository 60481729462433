import React from "react";
import { CheckSvg, ExclusiveSvg, LogoSvg, Security } from "../assets/svg";
import LogoImage from "../assets/image/LogoImage.png";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { dataChart } from "../utils/config";
import { Slider } from "@mui/material";
import whitepaper from "../assets/whitepaper.pdf";

function About() {
  return (
    <div
      id="about"
      className="lg:min-h-[862px] mt-[60px] w-[95%] lg:w-full max-w-[1320px] gap-4 lg:gap-2"
    >
      <div
        id="card1"
        className="bg-gradient-to-r from-[#ff00f731] to-[#10101000] border card lg:max-h-[514px] overflow-hidden border-[#1E1E1E] flex lg:flex-row flex-col rounded-[32px] w-full"
      >
        <div className="w-full lg:w-[40%] flex flex-col order-2 lg:order-1 justify-center gap-3 md:gap-6 p-4 lg:p-16">
          <span className="flex items-center text-[40px] gap-3">
            <LogoSvg className="w-[40px]" />
            NUMBER
          </span>
          <span className="text-[#CDCDCD]">
            Gone are the days of tedious verification processes and the
            relinquishment of personal data. 0xNumber is your silent partner in
            the quest for discreet and autonomous control over your numerical
            assets. Whether you’re an entrepreneur safeguarding your operations
            or an individual who prioritizes privacy, our platform is designed
            to serve your needs without compromise.
          </span>
        </div>
        <div className="w-full lg:w-[60%] order-1 lg:order-2 flex items-center p-16 justify-center">
          <img src={LogoImage} alt="" />
        </div>
      </div>
      <div
        id="card2"
        className="bg-[#101010] border card lg:max-h-[514px] overflow-hidden border-[#1E1E1E] flex lg:flex-row flex-col rounded-[32px] w-full"
      >
        <div className="w-full lg:w-1/2 flex flex-col order-2 lg:order-1 justify-center gap-3 md:gap-6 p-4 lg:p-16">
          <span className="flex items-center text-[24px] lg:text-[40px] gap-3">
            The Future of Number Ownership
          </span>
          <span className="text-[#CDCDCD] text-sm md:text-base">
            0xNumber revolutionizes phone number acquisition with decentralized
            technology, bypassing KYC protocols for secure, anonymous purchases.
            Tailored for entrepreneurs and privacy-focused users, it offers
            discreet ownership control, ensuring privacy and convenience in
            digital communications.
          </span>
          <div className="flex flex-col justify-center items-start gap-2 md:gap-3">
            <div className="flex items-center w-full md:w-[80%]">
              <span className="flex items-center gap-3 text-xs w-1/2 font-bold">
                <CheckSvg />
                Instant Ownership
              </span>
              <span className="flex items-center gap-3 text-xs w-1/2 font-bold">
                <CheckSvg />
                Secure Transactions
              </span>
            </div>
            <div className="flex items-center  w-full md:w-[80%]">
              <span className="flex items-center gap-3 text-xs w-1/2 font-bold">
                <CheckSvg />
                Global Access
              </span>
              <span className="flex items-center gap-3 text-xs w-1/2 font-bold">
                <CheckSvg />
                No KYC
              </span>
            </div>
          </div>
          <button className="px-4 py-2 w-fit border border-white rounded-full hover:bg-white hover:text-black duration-150 flex items-center">
            Get Started Now
            <KeyboardArrowRightIcon />
          </button>
        </div>
        <div className="w-full lg:w-1/2 bg-gradient-to-r from-[#ff00f713] to-[#10101000] order-1 lg:order-2 flex items-center p-4 lg:p-16 justify-center">
          <Security className="w-[90%]" />
        </div>
      </div>
      <div
        id="card3"
        className="bg-[#101010] border card lg:max-h-[514px] overflow-hidden border-[#1E1E1E] flex lg:flex-row flex-col rounded-[32px] w-full"
      >
        <div className="w-full lg:w-1/2 bg-gradient-to-r from-[#ff00f731] to-[#10101000] flex items-center lg:p-16 p-4 justify-center">
          <ExclusiveSvg className="w-[90%]" />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col justify-center gap-3 md:gap-6 lg:p-16 p-4">
          <span className="flex items-center text-[32px] lg:text-[40px]  gap-3">
            Exclusive & Unique Aspects of 0xNumber
          </span>
          <span className="text-[#CDCDCD] text-sm md:text-base">
            0xNumber is at the forefront of innovation in the rapidly evolving
            cryptocurrency landscape, committed to growth and introducing new
            DeFi concepts and services. As early adopters, we leverage unique
            opportunities to make an impact and stand out in the market.
          </span>
          <div className="flex flex-col justify-center items-start gap-3">
            <div className="flex items-center w-full">
              <span className="flex items-center gap-3 w-1/2 font-bold text-xs md:text-sm">
                <CheckSvg />
                Pioneering technology
              </span>
              <span className="flex items-center gap-3 w-1/2 font-bold text-xs md:text-sm">
                <CheckSvg />
                Streamlined decentralization
              </span>
            </div>
            <div className="flex items-center w-full">
              <span className="flex items-center gap-3 w-1/2 font-bold text-xs md:text-sm">
                <CheckSvg />
                Comprehensive tools
              </span>
              <span className="flex items-center gap-3 w-1/2 font-bold text-xs md:text-sm">
                <CheckSvg />
                Telegram integration
              </span>
            </div>
          </div>
          <a
            href={whitepaper}
            download="whitepaper.pdf"
            className="px-4 py-2 w-fit border border-white rounded-full hover:bg-white hover:text-black duration-150 flex items-center"
          >
            Whitepaper
            <KeyboardArrowRightIcon />
          </a>
        </div>
      </div>
      <div
        id="revenue"
        className="bg-[#101010] border card lg:max-h-[514px] overflow-hidden border-[#1E1E1E] flex lg:flex-row flex-col rounded-[32px] w-full"
      >
        <div className="w-full lg:w-1/2 bg-gradient-to-r from-[#ff00f731] to-[#10101000] flex flex-col items-center gap-3 p-4 md:p-8 justify-center">
          <div className="bg-gradient-to-r from-[#ff00f717] to-[#10101000] rounded-xl border p-4 border-[#1E1E1E] h-[250px] flex flex-col items-start gap-3 w-full">
            <span className="text-lg">Platform statistics</span>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                width={500}
                data={dataChart}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis className="text-xs" stroke="#CDCDCD" dataKey="name" />
                <YAxis className="text-xs" stroke="#CDCDCD" />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="uv"
                  stroke="#8884d8"
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <div className="bg-gradient-to-r from-[#ff00f717] to-[#10101000] rounded-xl border px-4 py-5 border-[#1E1E1E]  flex flex-col items-start gap-3 w-full">
            <div className="flex flex-col w-full">
              <span className="text-lg mb-2">Platform statistics</span>
              <div className="flex flex-col w-full items-start gap-5">
                <span className="text-sm text-[#CDCDCD]">Numbers Sold</span>
                <Slider
                  max={20000}
                  disabled
                  sx={{
                    "& .MuiSlider-valueLabelCircle": {
                      position: "absolute",
                      top: -23,
                      fontSize: "10px",
                    },
                    "& .MuiSlider-thumb": {
                      color: "transparent",
                      top: 5,
                      borderLeft: "10px solid transparent",
                      borderRight: "10px solid transparent",
                      borderTop: "13px solid #FF00F5",
                    },
                    "& .MuiSlider-track": {
                      color: "#FF00F5",
                    },
                    "& .MuiSlider-rail": {
                      color: "#acc4e4",
                      height: "1px",
                    },
                    "& .MuiSlider-active": {
                      color: "green",
                    },
                  }}
                  // value={11.7k}
                  value={11700}
                  min={0}
                  valueLabelDisplay="on"
                />
              </div>
              <div className="flex flex-col w-full items-start gap-4">
                <span className="text-sm text-[#CDCDCD]">
                  Account Activation
                </span>
                <Slider
                  max={4000}
                  disabled
                  sx={{
                    "& .MuiSlider-valueLabelCircle": {
                      position: "absolute",
                      top: -23,
                      fontSize: "10px",
                    },
                    "& .MuiSlider-thumb": {
                      color: "transparent",
                      top: 5,
                      borderLeft: "10px solid transparent",
                      borderRight: "10px solid transparent",
                      borderTop: "13px solid #FF00F5",
                    },
                    "& .MuiSlider-track": {
                      color: "#FF00F5",
                    },
                    "& .MuiSlider-rail": {
                      color: "#acc4e4",
                      height: "1px",
                    },
                    "& .MuiSlider-active": {
                      color: "green",
                    },
                  }}
                  // value={11.7k}
                  value={1200}
                  min={0}
                  valueLabelDisplay="on"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2 flex flex-col justify-center gap-6 p-4 lg:p-16">
          <span className="flex items-center text-[32px] lg:text-[40px] gap-3">
            Our platform ensures a dynamic revenue model.
          </span>
          <span className="text-[#CDCDCD] text-sm md:text-base">
            Platform distributes 80% of revenues weekly to holders with at least
            0.3% of total supply and shares 20% of transaction taxes among all
            holders on every trade, ensuring equitable growth and sustained
            performance.
          </span>
          <div className="flex flex-col justify-center items-start gap-3">
            <div className="flex items-center w-full">
              <span className="flex items-center gap-3 w-1/2 font-bold text-xs md:text-sm">
                <CheckSvg />
                Revenue Sharing
              </span>
              <span className="flex items-center gap-3 w-1/2 font-bold text-xs md:text-sm">
                <CheckSvg />
                Reflections for Holders
              </span>
            </div>
            <div className="flex items-center w-full">
              <span className="flex items-center gap-3 w-1/2 font-bold text-xs md:text-sm">
                <CheckSvg />
                Automatically Distributed
              </span>
              <span className="flex items-center gap-3 w-1/2 font-bold text-xs md:text-sm">
                <CheckSvg />
                On-chain transactions.
              </span>
            </div>
          </div>
          <button className="px-4 py-2 w-fit border border-white rounded-full hover:bg-white hover:text-black duration-150 flex items-center">
            Explore Our Platform
            <KeyboardArrowRightIcon />
          </button>
        </div>
      </div>
    </div>
  );
}

export default About;
