import React from "react";
import { motion } from "framer-motion";
import { Alibaba, AwsSvg, Cloud, Dummy1, Ibm } from "../assets/svg";

function Platform() {
  return (
    <div className=" lg:min-h-[862px] mt-10 w-[95%]  md:w-full max-w-[1320px] flex flex-col justify-center items-center">
      <motion.span
        initial={{ translateY: 100, opacity: 0 }}
        whileInView={{ translateY: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
        className="text-[24px] text-center lg:text-[32px]"
      >
        Powering Platform Through Collaborative Partnerships
      </motion.span>
      <motion.span
        initial={{ translateY: 100, opacity: 0 }}
        whileInView={{ translateY: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
        className="text-sm md:text-[18px] mt-3 w-[90%] lg:w-[55%] text-[#CDCDCD] text-center"
      >
        Exploring the Realm of Strategic Partnerships: Uniting Forces for
        Unrivaled Productivity Solutions. Unlock the Potential of Collaborative
        Ventures: Where Innovation Meets Productivity Excellence.
      </motion.span>
      <div className="flex flex-col lg:flex-row items-center w-full gap-3 md:gap-6 mt-10">
        <motion.div
          initial={{
            translateY: 300,
            translateX: -200,
            opacity: 0,
            rotate: -20,
          }}
          whileInView={{ translateY: 0, translateX: 0, opacity: 1, rotate: 0 }}
          viewport={{ once: true }}
          className="flex flex-col md:flex-row lg:flex-col items-center w-full md:w-[80%] gap-3 md:gap-6"
        >
          <div className="bg-gradient-to-r from-[#ff00f731] to-[#10101000] h-[120px] border px-4 w-full rounded-2xl border-[#1E1E1E] flex items-center gap-3">
            <AwsSvg className="w-[30%]" />
            <div className="w-[70%] gap-3 md:gap-2 lg:gap-3 flex flex-col items-start justify-between">
              <span className="lg:text-lg --text-privacy">Amazon AWS</span>
              <span className="text-sm text-[#CDCDCD]">
                Smooth data integration for third-party tool
              </span>
            </div>
          </div>
          <div className="bg-gradient-to-r from-[#ff00f731] to-[#10101000] h-[120px] border px-4 w-full rounded-2xl border-[#1E1E1E] flex items-center gap-3">
            <Cloud className="w-[30%]" />
            <div className="w-[70%] gap-3 md:gap-2 lg:gap-3 flex flex-col items-start justify-between">
              <span className="lg:text-lg --text-privacy">Google Cloud</span>
              <span className="text-sm text-[#CDCDCD]">
                Expanding capacity and infrastructure
              </span>
            </div>
          </div>
        </motion.div>
        <motion.div
          initial={{
            translateY: 200,
            translateX: 200,
            opacity: 0,
            rotate: -20,
          }}
          whileInView={{ translateY: 0, translateX: 0, opacity: 1, rotate: 0 }}
          viewport={{ once: true }}
          className="flex flex-col md:flex-row lg:flex-col items-center w-full md:w-[80%] gap-3 md:gap-6"
        >
          <div className="bg-gradient-to-r from-[#ff00f731] to-[#10101000] h-[120px] border px-4 w-full rounded-2xl border-[#1E1E1E] flex items-center gap-3">
            <Alibaba className="w-[30%]" />
            <div className="w-[70%] gap-3 md:gap-2 lg:gap-3 flex flex-col items-start justify-between">
              <span className="lg:text-lg --text-privacy">Alibaba Cloud</span>
              <span className="text-sm text-[#CDCDCD]">
                Smooth data integration for third-party tools
              </span>
            </div>
          </div>
          <div className="bg-gradient-to-r from-[#ff00f731] to-[#10101000] h-[120px] border px-4 w-full rounded-2xl border-[#1E1E1E] flex items-center gap-3">
            <Ibm className="w-[30%]" />
            <div className="w-[70%] gap-3 md:gap-2 lg:gap-3 flex flex-col items-start justify-between">
              <span className="lg:text-lg --text-privacy">IBM</span>
              <span className="text-sm text-[#CDCDCD]">
                Streamlining business solutions
              </span>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Platform;
