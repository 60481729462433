import React from 'react'
import { motion } from "framer-motion"

function InformationMobile() {
    return (
        <div className='text-white w-full mt-10 flex gap-3 flex-col items-center'>
            <div className='w-full flex md:flex-row flex-col gap-3'>
                <motion.div
                    initial={{ translateY: 300, opacity: 0, }}
                    whileInView={{ translateY: 0, opacity: 1, }}
                    viewport={{ once: true }}
                    className='flex flex-col md:flex-row items-center w-full md:w-[80%] gap-3 md:gap-6'>
                    <div className='bg-gradient-to-r md:h-[224px] from-[#ff00f731] to-[#10101000] border p-4 w-full rounded-2xl border-[#1E1E1E] flex items-center gap-3'>
                        <div className=' gap-6 md:gap-2 flex flex-col text-[#CDCDCD] items-start justify-between h-full'>
                            <span className='--text-privacy text-[24px] font-bold'>$OXN</span>
                            <span className='text-md'>Token Symbol : $OXN</span>
                            <span className='text-md'>Token Name: 0xNumber</span>
                            <span className='text-md'>Contract: <span className='text-sm'>0x9012744B7A564623b6C3E40b144fc196bdeDf1A9</span></span>
                            <a href='/' className=' text-md text-[#FF00F5]'>Etherscan</a>
                        </div>
                    </div>
                </motion.div>
                <motion.div
                    initial={{ translateY: 300, opacity: 0, }}
                    whileInView={{ translateY: 0, opacity: 1, }}
                    viewport={{ once: true }}
                    className='flex flex-col md:flex-row items-center w-full md:w-[80%] gap-3 md:gap-6'>
                    <div className='bg-gradient-to-r md:h-[224px] from-[#ff00f731] to-[#10101000] border p-4 w-full rounded-2xl border-[#1E1E1E] flex items-center gap-3'>
                        <div className=' gap-6 md:gap-2 flex flex-col text-[#CDCDCD] items-start justify-between h-full'>
                            <span className='--text-privacy text-[24px] font-bold'>Tokenomics</span>
                            <span className='text-md'>Total Supply : 10,000,000</span>
                            <span className='text-md'>Circulation Supply: 10,000,000</span>
                            <span className='text-md'>Tax : 5%</span>
                            <a href='/' className=' text-md text-[#FF00F5]'>Unicrypt Liquidity Locked</a>
                        </div>
                    </div>
                </motion.div>
            </div>
            <motion.div
                initial={{ translateY: 300, opacity: 0, }}
                whileInView={{ translateY: 0, opacity: 1, }}
                viewport={{ once: true }}
                className='flex flex-col md:flex-row items-center w-full gap-3 md:gap-6'>
                <div className='bg-gradient-to-r h-[340px] relative from-[#ff00f731] to-[#10101000] border p-4 w-full rounded-2xl border-[#1E1E1E] flex flex-col overflow-hidden items-center gap-3'>
                    <span className='rock text-[28px] mt-10'>Token Information</span>
                    <div className='absolute bottom-0 w-full z-0'>
                        <svg className="waves h-[200px]" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                            <defs>
                                <defs>
                                    <linearGradient id="waveGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                        <stop offset="0%" stopColor="rgba(83, 78, 237, 1)" />
                                    </linearGradient>
                                    <linearGradient id="waveGradient2" x1="0%" y1="0%" x2="100%" y2="0%">
                                        <stop offset="0%" stopColor="rgba(255, 0, 245, 1)" />
                                    </linearGradient>
                                </defs>
                                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                            </defs>
                            <g className="parallax">
                                <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 0, 245, 0.8)" />
                                <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(115, 110, 255, 0.5)" />
                                <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(83, 78, 237, 0.7)" />
                                <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(115, 110, 255, 0.5)" />
                            </g>
                        </svg>
                    </div>
                </div>
            </motion.div>
            <div className='w-full flex md:flex-row flex-col gap-3'>
                <motion.div
                    initial={{ translateY: 300, opacity: 0, }}
                    whileInView={{ translateY: 0, opacity: 1, }}
                    viewport={{ once: true }}
                    className='flex flex-col md:flex-row items-center w-full md:w-[80%] gap-3 md:gap-6'>
                    <div className='bg-gradient-to-r from-[#ff00f731] md:h-[224px] to-[#10101000] border p-4 w-full rounded-2xl border-[#1E1E1E] flex items-center gap-3'>
                        <div className='gap-6 md:gap-2 flex flex-col text-[#CDCDCD] items-start justify-between h-full'>
                            <span className='--text-privacy text-[24px] font-bold'>Revenue Sharing</span>
                            <span className='text-md'>0xNumber automatically shares 80% of platform revenues weekly with holders who maintain a minimum of 0.3% of the total supply.</span>
                            <a href='/' className=' text-md text-[#FF00F5]'>Platform</a>
                        </div>
                    </div>
                </motion.div>
                <motion.div
                    initial={{ translateY: 300, opacity: 0, }}
                    whileInView={{ translateY: 0, opacity: 1, }}
                    viewport={{ once: true }}
                    className='flex flex-col md:flex-row items-center w-full md:w-[80%] gap-3 md:gap-6'>
                    <div className='bg-gradient-to-r from-[#ff00f731] md:h-[224px] to-[#10101000] border p-4 w-full rounded-2xl border-[#1E1E1E] flex items-center gap-3'>
                        <div className=' gap-6 md:gap-2 flex flex-col text-[#CDCDCD] items-start justify-between h-full'>
                            <span className='--text-privacy text-[24px] font-bold'>Reflection</span>
                            <span className='text-md'>20% of tax from each transaction is automatically distributed to all holders, with no minimum holding requirement, upon every buy and sell.</span>
                            <a href='/' className=' text-md text-[#FF00F5]'>Contract Renounced</a>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default InformationMobile