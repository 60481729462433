import React from 'react'

import MenuIcon from '@mui/icons-material/Menu';
import NavLogo from '../assets/image/NavLogo.png'
import { IconButton, Menu, MenuItem } from '@mui/material';
import { navRoute } from '../utils/config';


function Navbar() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className='w-[95%] text-white md:w-full bg-[#121212] flex items-center rounded-xl px-6 max-w-[1320px] z-[999] top-2 py-4 fixed justify-between'>
            <div className='flex items-center'>
                <img src={NavLogo} className=' md:w-[90%]' alt='' />
            </div>
            <div className='blok md:hidden'>
                <IconButton onClick={handleClick} className='!p-0'>
                    <MenuIcon className='text-white' />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {navRoute.map((dt, i) => (
                        <a href={dt.link} key={i}>
                            <MenuItem onClick={handleClose}>{dt.label}</MenuItem>
                        </a>
                    ))}
                </Menu>
            </div>
            <div className='hidden md:flex gap-6 items-center justify-center'>
                {navRoute.map((dt, i) => (
                    <a className='border border-transparent hover:border-b duration-200 hover:border-b-white' key={i} href={dt.link}>{dt.label}</a>
                ))}
            </div>
            <div className='hidden lg:flex items-center gap-2 md:gap-3'>
                <a className='w-fit' href='https://t.me/oxnumber_bot'>
                    <button className='bg-transparent duration-200 hover:scale-95 px-3 md:px-4 py-1 text-xs md:text-sm rounded-full border border-white text-white'>Platform</button>
                </a>
                 <a className='w-fit' href='https://dapp.0xnumber.io/'>
                    <button className='bg-white duration-200 hover:scale-95 px-3 md:px-4 py-1 text-xs md:text-sm rounded-full border border-white text-black'>Dapp</button>
                </a>
            </div>
        </div>
    )
}

export default Navbar