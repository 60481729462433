import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Main from './Main'
import Footer from './components/Footer';
import Navbar from './components/Navbar';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  }
]);

function App() {
  return (
    <React.StrictMode>
      <div className='w-full flex items-center justify-center'>
        <Navbar />
      </div>
      <RouterProvider router={router} />
      <Footer />
    </React.StrictMode>
  )
}

export default App