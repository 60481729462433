import React from 'react'
import { motion } from "framer-motion"
import { Maps, Personal } from '../assets/svg'

function Process() {
  return (
    <div id='process' className=' lg:min-h-[862px] mt-[50px] lg:mt-[200px] w-[95%] lg:w-full max-w-[1320px] flex flex-col justify-center gap-2 items-center'>
    <motion.span
      initial={{ translateY: -100, opacity: 0 }}
      whileInView={{ translateY: 0, opacity: 1 }}
      viewport={{ once: true }}
      className='text-[28px] md:text-[36px]'>Simplifying The Process</motion.span>
    <div className='mt-4 lg:mt-10 flex flex-col w-full gap-3 lg:gap-6'>
      <div className='w-full flex md:flex-row flex-col items-start gap-3 lg:gap-6'>
        <motion.div
          initial={{ translateY: -100, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          viewport={{ once: true }}
          className='w-full md:w-[40%] lg:w-1/4 rounded-2xl h-[508px] md:h-[560px] lg:h-[508px] overflow-hidden bg-[#101010] border-[2px] border-[#101010] flex flex-col'>
          <div className='w-full flex items-center h-[60%] bg-[#000000] justify-center'><Maps height={'90%'} /></div>
          <div className='flex flex-col p-4 h-[40%] items-start justify-between gap-3'>
            <span className='text-[20px]'>Worldwide Expansion</span>
            <span className='text-[#CDCDCD] text-sm'>Our steadfast commitment to global expansion fuels our ongoing efforts to serve customers worldwide.</span>
            <span className='flex items-center gap-3 --text-privacy'><div className='h-2 w-2 rounded-full bg-gradient-to-r from-[#FE00FE] to-[#0000FF]'></div>180</span>
            <span className='text-[#CDCDCD] text-sm'>Countries supported</span>
          </div>
        </motion.div>
        <motion.div
          initial={{ translateY: -100, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          viewport={{ once: true }}
          className='w-full md:w-[60%] lg:w-[74%] bg-[#101010] rounded-2xl h-[508px] md:h-[560px] lg:h-[508px] overflow-hidden border-[2px] border-[#101010] flex flex-col'>
          <div className='w-full h-[60%] flex items-end bg-[#000000] justify-center pt-4'>
            <Personal height={'90%'} />
          </div>
          <div className='flex flex-col p-4 h-[40%] items-start justify-between gap-3'>
            <span className='text-[20px]'>Accessibility</span>
            <span className='text-[#CDCDCD] text-sm w-full md:w-1/2'>Unlock an expansive array of possibilities with access to a diverse portfolio of over 700 websites and applications.</span>
            <span className='flex items-center gap-3 --text-privacy'><div className='h-2 w-2 rounded-full bg-gradient-to-r from-[#FE00FE] to-[#0000FF]'></div>700+</span>
            <span className='text-[#CDCDCD] text-sm'>Sites and Apps supported</span>
          </div>
        </motion.div>
      </div>
      <div className='w-full flex md:flex-row flex-col items-start gap-3 lg:gap-6'>
        <motion.div
          initial={{ translateX: -100, opacity: 0 }}
          whileInView={{ translateX: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className='w-full md:w-[60%] lg:w-[70%] bg-[#101010] rounded-2xl overflow-hidden border-[2px] border-[#101010] flex flex-col'>
          <div className='flex flex-col p-4 items-start justify-between md:h-[220px] lg:h-[204px] gap-3'>
            <span className='text-[20px]'>Scalable</span>
            <span className='text-[#CDCDCD] text-sm w-full md:w-1/2'><span className='font-bold text-white'>0xNumber</span> processes thousands of SMS per second, while keeping fees consistent for customers</span>
            <span className='flex items-center gap-3 --text-privacy'><div className='h-2 w-2 rounded-full bg-gradient-to-r from-[#FE00FE] to-[#0000FF]'></div>1,291</span>
            <span className='text-[#CDCDCD] text-sm'>Total account activation</span>
          </div>
        </motion.div>
        <motion.div
          initial={{ translateX: 100, opacity: 0 }}
          whileInView={{ translateX: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className='w-full md:w-[40%] lg:w-[30%] rounded-2xl overflow-hidden bg-[#101010] border-[2px] border-[#101010] flex flex-col'>
          <div className='flex flex-col p-4 md:h-[220px] lg:h-[204px] items-start justify-between gap-3'>
            <span className='text-[20px]'>Effortless Funding</span>
            <span className='text-[#CDCDCD] text-sm'>Streamlined Payment Convenience: Effortlessly replenish your account using an e-wallet, bank card, or cryptocurrency of your choice.</span>
            <span className='flex items-center gap-3 --text-privacy'><div className='h-2 w-2 rounded-full bg-gradient-to-r from-[#FE00FE] to-[#0000FF]'></div>8</span>
            <span className='text-[#CDCDCD] text-sm'>Supported payment options</span>
          </div>
        </motion.div>

      </div>
    </div>
  </div>
  )
}

export default Process