import React from 'react'
import { motion } from "framer-motion"
import { LogoToken } from '../assets/svg'
import Marquee from 'react-fast-marquee';
import Gecko from '../assets/image/Gecko.png'
import Rectangle1 from '../assets/image/Rectangle1.png'
import Rectangle2 from '../assets/image/Rectangle2.png'
import Rectangle3 from '../assets/image/Rectangle3.png'
import Rectangle4 from '../assets/image/Rectangle4.png'
import Etherscan from '../assets/image/Etherscan.png'
import Dextools from '../assets/image/Dextools.png'
import Uniswap from '../assets/image/Uniswap.png'

function Information() {
    return (
        <div className='lg:min-h-[162px] w-full max-w-[1320px] flex flex-col justify-start gap-6 items-center'>
            <span className='hidden md:block lg:text-[32px]'>Token Information</span>
            <div className='flex w-full relative justify-between'>
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    className='flex flex-col justify-between w-[47%] lg:w-1/2 gap-[160px] md:gap-[250px] lg:gap-3 h-full'>
                    <div className='relative h-[130px] md:h-[230px] lg:h-[300px]'>
                        <div className='w-full relative flex text-[10px] md:text-sm flex-col justify-between items-start py-2 md:py-4 px-3 md:px-6 z-10 h-full'>
                            <span className='--text-0x lg:text-[24px] font-bold'>$OXN</span>
                            <span>Token Symbol : $OXN</span>
                            <span className='border-t-[2px] border-t-[#1E1E1E]'>Token Name: 0xNumber</span>
                            <span className='w-[40%] truncate border-t-[2px] border-t-[#1E1E1E]'>Contract: 0x9012744B7A564623b6C3E40b144fc196bdeDf1A9</span>
                            <a href='/' className='text-[#FF00F5]'>Etherscan</a>
                        </div>
                        <img className='absolute z-0 top-0 left-0' src={Rectangle1} alt='' />
                    </div>
                    <div className='relative h-[130px] md:h-[230px] lg:h-[300px]'>
                        <div className='w-full relative text-[12px] md:text-base flex flex-col justify-between items-start  py-2 lg:py-4 px-3 lg:px-6 z-10 h-full'>
                            <span className='--text-0x lg:text-[24px] font-bold'>Revenue Sharing</span>
                            <span className='lg:w-[40%] w-[78%] md:w-[70%] md:text-[12px] lg:text-base text-[9px]'>0xNumber automatically shares 80% of platform revenues weekly with holders who maintain a minimum of 0.3% of the total supply.</span>
                            <a href='/' className='text-[#FF00F5]'>Platform</a>
                        </div>
                        <img className='absolute z-0 top-0 left-0' src={Rectangle2} alt='' />
                    </div>
                </motion.div>
                <div className='absolute flex w-[50%] h-1/2 lg:h-full items-center justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                    <div className="hexagon-1 relative h-full w-full">
                        <div className='relative z-10 flex flex-col justify-center items-center mt-[-200px] gap-10'>
                            <LogoToken className='relative z-10' />
                        </div>
                        <div className='absolute  bottom-0 z-0'>
                            <svg className="waves h-[100px] lg:h-[200px] lg:scale-[2.3]" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                                <defs>
                                    <defs>
                                        <linearGradient id="waveGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                            <stop offset="0%" stopColor="rgba(83, 78, 237, 1)" />
                                        </linearGradient>
                                        <linearGradient id="waveGradient2" x1="0%" y1="0%" x2="100%" y2="0%">
                                            <stop offset="0%" stopColor="rgba(255, 0, 245, 1)" />
                                        </linearGradient>
                                    </defs>
                                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                                </defs>
                                <g className="parallax">
                                    <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 0, 245, 0.8)" />
                                    <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(115, 110, 255, 0.5)" />
                                    <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(83, 78, 237, 0.7)" />
                                    <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(115, 110, 255, 0.5)" />
                                </g>
                            </svg>
                        </div>
                    </div>
                    {/* <div className='absolute lg:top-10 flex gap-10 flex-col justify-center items-center'>
                </div>
                <img src={Polygon} className='' alt='' /> */}
                </div>
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    className='flex flex-col justify-between w-[47%] lg:w-1/2 gap-[120px] md:gap-[250px] lg:gap-3 h-full'>
                    <div className='relative  h-[130px] md:h-[230px] lg:h-[300px]'>
                        <div className='w-full relative text-[10px] md:text-sm flex flex-col justify-between text-right items-end py-4 px-6 z-10 h-full'>
                            <span className='--text-0x lg:text-[24px] font-bold'>Tokenomics</span>
                            <span>Total Supply : 10,000,000</span>
                            <span className='border-t-[2px] border-t-[#1E1E1E]'>Supply: 10,000,000</span>
                            <span className='w-[40%] truncate border-t-[2px] border-t-[#1E1E1E]'>Tax : 5%</span>
                            <a href='/' className='text-[#FF00F5] w-[70%] truncate'>Unicrypt Liquidity Locked</a>
                        </div>
                        <img className='absolute z-0 right-0 top-0' src={Rectangle3} alt='' />
                    </div>
                    <div className='relative h-[130px] md:h-[230px] lg:h-[300px]'>
                        <div className='w-full relative text-[12px] md:text-base flex flex-col justify-between text-right items-end  py-2 lg:py-4 px-3 lg:px-6 z-10 h-full'>
                            <span className='--text-0x lg:text-[24px] font-bold'>Reflection</span>
                            <span className='lg:w-[40%] md:w-[70%] w-[70%] md:text-[12px] lg:text-base text-[9px]'>20% of tax from each transaction is automatically distributed to all holders, with no minimum holding requirement, upon every buy and sell.</span>
                            <a href='/' className='text-[#FF00F5]'>Contract Renounced</a>
                        </div>
                        <img className='absolute z-0 right-0 top-0' src={Rectangle4} alt='' />
                    </div>
                </motion.div>
            </div>
            <div className='w-full gap-14 mt-10 border-[#1E1E1E] border bg-[#101010] md:hidden flex items-center justify-center py-4 rounded-xl'>
                <Marquee className='w-[95%]'>
                    <img src={Gecko} className='ml-4' alt='' />
                    <img src={Uniswap} className='ml-4' alt='' />
                    <img src={Etherscan} className='ml-4' alt='' />
                    <img src={Dextools} className='ml-4' alt='' />
                </Marquee>
            </div>
            <div className='w-full  gap-14 mt-6 border-[#2F1A52] border bg-gradient-to-r from-[#ff00f731] to-[#10101000] hidden rounded-b-[64px] rounded-t-xl md:flex items-center justify-center py-4'>
                <img src={Gecko} className='ml-4' alt='' />
                <img src={Uniswap} className='ml-4' alt='' />
                <img src={Etherscan} className='ml-4' alt='' />
                <img src={Dextools} className='ml-4' alt='' />
            </div>
        </div>
    )
}

export default Information