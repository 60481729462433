export const navRoute = [
  {
    label: 'Home',
    link: '#home'
  }, {
    label: 'About',
    link: '#about'
  }, {
    label: 'Revenue',
    link: '#revenue'
  }, {
    label: 'Process',
    link: '#process'
  }
  // , {
  //   label: 'Roadmap',
  //   link: '#roadmap'
  // }, {
  //   label: 'Community',
  //   link: '#community'
  // }
]

export const dataChart = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export const copyToClipboard = (text, name) => {
  const textToCopy = text;

  const textarea = document.createElement('textarea');
  textarea.value = textToCopy;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);

  alert(`${name} copied to clipboard`);
};